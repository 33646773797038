import React, { useState, useRef } from "react";
// PrimeReact components
import { TabView, TabPanel } from "primereact/tabview";
// Redux
import { connect } from "react-redux";
import { setDriveSession } from "actions/sessionActions";
// Custom components
import {
  DriverStatus,
  DriveListHeader,
  DriveListTable,
  DailyReportTable,
} from "./DriveListViews/index";
// Localization
import { useIntl } from "react-intl";
import { MESSAGE_KEYS, QUERIES } from "assets/staticData/enums";
import {
  dateToQueryString,
  initLogger,
  sendQuery,
  sortObjectArray,
} from "common/Helpers";

const logger = initLogger("drivelist");

const DriveList = ({
  pending,
  appointments,
  handleSelection,
  currentUser,
  failedUploads,
  handleRefresh,
  drivers,
  handleReupload,
  isAdmin,
  appointmentFilter,
  selectedIndex,
  selectedDate,
  selectedUser,
  setDriveSession,
  cars,
  driverStatus,
  driverStatusCar,
  updateStatus,
  handleStateWSUpdate,
  handleGarageWSUpdate,
}) => {
  const intl = useIntl();

  const headerRef = useRef();

  const filteredAppointments = appointments.filter((appointment) => {
    let hasEmptyRequiredInput = false;

    if (appointment?.appointment?.state) {
      // return journey
      if (
        appointment.appointment.state === 1 &&
        (appointment.appointment.kilometerReturn === null ||
          appointment.appointment.kilometer === null ||
          appointment.appointment.car === null ||
          appointment.appointment.carIdReturn === null)
      ) {
        hasEmptyRequiredInput = true;
      }

      // outward
      if (
        appointment.appointment.state === 2 &&
        (appointment.appointment.kilometer === null ||
          appointment.appointment.car === null)
      ) {
        hasEmptyRequiredInput = true;
      }
    }

    if (
      appointment.appointment.driverUpload <=
        0 /* || appointment.appointment.state === 1 */ ||
      appointment.appointment.state === 13 ||
      hasEmptyRequiredInput
    ) {
      const {
        appointment: {
          firstDriver,
          firstDriverReturn,
          secondDriver,
          secondDriverReturn,
          state,
        },
      } = appointment;
      return appointmentFilter(
        {
          firstDriverId: firstDriver?.personId,
          firstDriverReturnId: firstDriverReturn?.personId,
          secondDriverId: secondDriver?.personId,
          secondDriverReturnId: secondDriverReturn?.personId,
          appointmentstate: state,
        },
        false
      );
    } else {
      return false;
    }
  });

  const unfilteredAppointments = [];
  let outwardTimes = [];

  const [selDate, setSelDate] = useState(null);
  const [driverList, setDriverList] = useState(drivers);

  const changeDate = (date) => {
    sendQuery(
      `${QUERIES.GET_DRIVERS}?date=${dateToQueryString(date)}`,
      "get"
    ).then(
      (response) => {
        if (response && typeof (response[Symbol.iterator] === "function")) {
          let drivers = [...response];
          sortObjectArray(drivers, "alias");
          setDriverList(drivers);
          setSelDate(date);
        }
      },
      (error) => {
        logger.warn("Error on driver fetch", error);
      }
    );
  };

  appointments.forEach((appointment) => {
    const { firstDriver, secondDriver, firstDriverReturn, secondDriverReturn } =
      appointment.appointment;
    // Check if the driver was involved in the outward- and return-drives.
    const participatedOutward = appointmentFilter(
      {
        firstDriverId: firstDriver?.personId,
        firstDriverReturnId: -1,
        secondDriverId: secondDriver?.personId,
        secondDriverReturnId: -1,
        appointmentstate: null,
      },
      false
    );
    const participatedReturn = appointmentFilter(
      {
        firstDriverId: -1,
        firstDriverReturnId: firstDriverReturn?.personId,
        secondDriverId: -1,
        secondDriverReturnId: secondDriverReturn?.personId,
        appointmentstate: 1,
      },
      false
    );
    // Check if title-box is checked.
    const TITLE_CHECKBOX_ID = 30;
    const titleValue = appointment.appointment?.appointmentChecklistItems.find(
      (item) => item.checklistItem.checklistItemId === TITLE_CHECKBOX_ID
    );
    const isTitle = titleValue?.itemState === true;
    appointment.title = isTitle;
    appointment.fiche = !isTitle;
    if (
      appointment.rueckfahrt === true &&
      participatedOutward &&
      participatedReturn
    ) {
      const RETURN_STATE_IDS = [1, 13];
      const groupedAppointment = [];
      const departure = {
        ...appointment,
        rueckfahrt: false,
        group: RETURN_STATE_IDS.includes(appointment.appointment.state),
      };
      const arrival = {
        ...appointment,
        fullFromAddress: appointment.fullToAddress,
        fullToAddress: appointment.fullFromAddress,
        group: true,
      };
      groupedAppointment.push(departure);
      const WAITING_CHECKBOX_ID = 26;
      const {
        appointment: { appointmentChecklistItems },
      } = appointment;
      const waitingValue = appointmentChecklistItems.find(
        (item) => item.checklistItem.checklistItemId === WAITING_CHECKBOX_ID
      );
      if (waitingValue?.itemState === true && waitingValue?.nbr_value > 0) {
        const waiting = {
          ...appointment,
          rueckfahrt: false,
          group: true,
        };
        groupedAppointment.push(waiting);
      }
      groupedAppointment.push(arrival);
      unfilteredAppointments.push(...groupedAppointment);
    } else {
      appointment.rueckfahrt = appointment.rueckfahrt && participatedReturn;
      appointment.group = participatedReturn;
      unfilteredAppointments.push(appointment);
    }
  });

  const { DRIVES_WORK_VIEW, DRIVES_REPORT_VIEW } = MESSAGE_KEYS;

  const [activeIndex, setActiveIndex] = useState(selectedIndex);

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
    setDriveSession({ user: selectedUser, date: selectedDate, index: e.index });
    if (headerRef?.current) {
      headerRef.current.updateValidations();
    }
  };

  return (
    <div>
      <DriverStatus
        cars={cars}
        driverStatus={driverStatus}
        driverStatusCar={driverStatusCar}
        updateStatus={updateStatus}
        handleStateWSUpdate={handleStateWSUpdate}
      />
      <DriveListHeader
        ref={headerRef}
        currentUser={currentUser}
        handleRefresh={handleRefresh}
        drivers={driverList}
        setSelDate={changeDate}
        allAppointments={unfilteredAppointments}
        handleGarageWSUpdate={handleGarageWSUpdate}
      />
      <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
        <TabPanel header={intl.formatMessage({ id: DRIVES_WORK_VIEW })}>
          <DriveListTable
            appointments={filteredAppointments}
            pending={pending}
            handleSelection={handleSelection}
            failedUploads={failedUploads}
            handleReupload={handleReupload}
            isAdmin={isAdmin}
            selDate={selDate}
          />
        </TabPanel>
        <TabPanel header={intl.formatMessage({ id: DRIVES_REPORT_VIEW })}>
          <DailyReportTable
            appointments={unfilteredAppointments}
            handleSelection={handleSelection}
            pending={pending}
            times={outwardTimes}
            handleRefresh={handleRefresh}
            selDate={selDate}
          />
        </TabPanel>
      </TabView>
    </div>
  );
};

const mapStateToProps = (state) => {
  try {
    const {
      session: { driveSession },
    } = state;
    return {
      selectedIndex: driveSession.index,
      selectedUser: driveSession.user,
      selectedDate: driveSession.date,
    };
  } catch (e) {
    return {
      selectedIndex: 0,
    };
  }
};

export default connect(mapStateToProps, { setDriveSession }, null, {
  forwardRef: true,
})(DriveList);
